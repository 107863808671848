<template>
	<div class="content text_font">
		<div class="screen-title">案件検索</div>
		<b-container class="pb-4" fluid>
			<b-row class="mx-0 mb-4 d-flex row-gap-20">
				<card class="w-100 my-0">
					<div class="row mx-2 mt-3 d-flex col-gap-30">
						<div class="col-md col-xs-12 px-0">
							<b-row class="mx-0 d-flex pb-27 col-gap-30">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="案件名"
										name="案件名"
										v-model="model.projectName"
										label="案件名"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0">
									<base-input
										:labelClasses="'custom-form-input-label'"
										label="クライアント名"
									>
										<el-select
											v-model="clientSelects"
											placeholder="選択なし"
											filterable
											clearable
										>
											<el-option
												v-for="option in clientOptions"
												:key="option.value"
												:label="option.label"
												:value="option.label"
											>
											</el-option>
										</el-select>
									</base-input>
								</div>
							</b-row>
						</div>
						<div class="col px-0">
							<b-row class="mx-0 custom__flex-direction col-gap-30 res_margin_bottom">
								<b-col class="px-0">
									<base-input :labelClasses="'custom-form-input-label'" label="開始日">
										<FunctionalCalendar
											v-model="projectStartDateRange"
											:date-format="'yyyy/mm/dd'"
											:is-multiple="true"
											:is-date-range="true"
											:hidden-elements="['leftAndRightDays']"
											:dayNames="dayNames"
											:monthNames="monthNames"
											:is-modal="true"
										></FunctionalCalendar>
									</base-input>
								</b-col>
								<b-col class="px-0">
									<base-input :labelClasses="'custom-form-input-label'" label="納品日">
										<FunctionalCalendar
											v-model="projectDeliveryDateRange"
											:date-format="'yyyy/mm/dd'"
											:is-multiple="true"
											:is-date-range="true"
											:hidden-elements="['leftAndRightDays']"
											:dayNames="dayNames"
											:monthNames="monthNames"
											:is-modal="true"
											:modalCustom="true"
										></FunctionalCalendar>
									</base-input>
								</b-col>
							</b-row>
						</div>
					</div>

					<b-row class="mx-2 pb-17">
						<b-col class="px-0">
							<base-input :labelClasses="'custom-form-input-label'" label="ステータス">
								<b-form-checkbox-group
									id="checkbox-group-1"
									v-model="selected"
									:options="statusOptions"
									name="flavour-1"
								></b-form-checkbox-group>
							</base-input>
						</b-col>
					</b-row>
				</card>
				<b-row
					class="mx-0 w-100 mt-2 d-flex pb-27 flex-row col-gap-30 justify-content-center align-items-center"
				>
					<button class="customButton customButton__search" @click="onSearch">
						検索
					</button>
				</b-row>
			</b-row>
			<card class="my-0 w-100">
				<b-row class="mx-4 d-flex align-items-center col-gap-20">
					<div class="elm-title">表示件数</div>
					<el-select
						class="w-147px"
						v-model="pagination.perPage"
						@change="onChangePagitionPerPage"
					>
						<el-option
							class="select-primary pagination-select text-font"
							v-for="item in pagination.perPageOptions"
							:key="item"
							:label="item"
							:value="item"
						>
						</el-option>
					</el-select>
				</b-row>
				<b-row class="mx-0 px-4 my-3">
					<el-table
						:data="queriedData"
						row-key="id"
						@sort-change="sortChange"
						v-touch:swipe="ScrollTable"
					>
						<el-table-column labelClassName="table-header" width="140">
							<template v-slot:default="table">
								<a target="_blank" :href="table.row.url">
									<button class="customButton customButton__processDirect">
										プロセス画面
									</button>
								</a>
							</template>
						</el-table-column>
						<el-table-column
							v-if="userAuth !== 3"
							labelClassName="table-header"
							width="120"
						>
							<template v-slot:default="table">
								<router-link
									:to="{
										path: 'editproject',
										query: {
											projectid: table.row.id,
										},
									}"
								>
									<button class="customButton customButton__edit">編集</button>
								</router-link>
							</template>
						</el-table-column>
						<el-table-column
							v-for="col in projectNameCol"
							:key="col.label"
							v-bind="col"
						>
							<template v-slot:default="table">
								{{ table.row.projectname }}
							</template>
						</el-table-column>
						<el-table-column
							v-for="column in tableColumns"
							:key="column.label"
							v-bind="column"
							labelClassName="table-header"
						>
						</el-table-column>
					</el-table>
					<img
						src="@/assets/Icon/ScrollHintldpi.svg"
						class="scoll_hint_img"
						v-show="!isScrolling"
						:class="{ not_display: !isMobile }"
					/>
				</b-row>
				<div class="w-100 d-flex justify-content-end justify-content-sm-between">
					<base-pagination
						class="w-100 d-flex justify-content-end"
						:current="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total"
						@change="paginationChanged($event)"
					>
					</base-pagination>
				</div>
			</card>
		</b-container>
	</div>
</template>
<script>
import { Select, Option } from 'element-ui';
import { Table, TableColumn } from 'element-ui';

import FunctionalCalendar from '../../components/DateRange/FunctionalCalendar.vue';

import { BasePagination } from '@/components';
import clientPaginationMixin from '../../mixin/clientPaginationMixin';
import status from '../../data/status';
import CommonConst from '../../util/const';

import socketClient from '../../services/socket/SOCKET';

export default {
	mixins: [clientPaginationMixin],
	components: {
		BasePagination,
		[Select.name]: Select,
		[Option.name]: Option,
		[Table.name]: Table,
		[TableColumn.name]: TableColumn,
		FunctionalCalendar,
	},
	data() {
		return {
			isMobile: true,
			isScrolling: false,
			projectStartDateRange: {
				dateRange: {
					start: '',
					end: '',
				},
			},
			projectDeliveryDateRange: {
				dateRange: {
					start: '',
					end: '',
				},
			},
			dayNames: CommonConst.DAY_NAMES,
			monthNames: CommonConst.MONTH_NAMES,

			model: {
				projectName: '',
			},
			selected: [],
			clientOptions: [],
			projectNameCol: [
				{
					prop: 'projectname',
					label: '案件名',
					minWidth: 150,
					sortable: true,
				},
			],
			tableColumns: [
				{
					prop: 'clientname',
					label: 'クライアント名',
					minWidth: 150,
					sortable: true,
				},
				{
					prop: 'status',
					label: 'ステータス',
					minWidth: 130,
					sortable: true,
				},
				{
					prop: 'startdate',
					label: '開始日',
					minWidth: 100,
					sortable: true,
				},
				{
					prop: 'delivery',
					label: '納品日',
					minWidth: 100,
					sortable: true,
				},
			],
			originalTableData: [],
			clientSelects: '',
			tableData: [],
			status: [],
			tableName: 'project-table',
			statusOptions: status,
		};
	},
	methods: {
		onChangePagitionPerPage() {
			this.pagination.currentPage = 1;
		},
		checkMobile() {
			let check = false;
			(function (a) {
				if (
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
						a
					) ||
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
						a.substr(0, 4)
					)
				)
					check = true;
			})(navigator.userAgent || navigator.vendor || window.opera);
			console.log(check);
			return check;
		},
		ScrollTable() {
			this.isScrolling = true;
		},

		onSearch() {
			this.pagination.currentPage = 1;
			let targetStartDateRange = [];
			let targetDeliveryDateRange = [];

			let filterProjectStartDateRange = this.filterDateRange(
				this.projectStartDateRange.dateRange
			);
			let filterProjectDeliveryDateRange = this.filterDateRange(
				this.projectDeliveryDateRange.dateRange
			);

			if (filterProjectStartDateRange) {
				filterProjectStartDateRange.map((date) => {
					targetStartDateRange.push(new Date(date).getTime());
				});
			}
			if (filterProjectDeliveryDateRange) {
				filterProjectDeliveryDateRange.map((date) => {
					targetDeliveryDateRange.push(new Date(date).getTime());
				});
			}
			if (this.clientSelects === '選択なし') {
				this.clientSelects = '';
			}
			let searchFilter = {
				projectName: this.model.projectName,
				clientName: this.clientSelects,
				startDateRange: targetStartDateRange,
				deliveryDateRange: targetDeliveryDateRange,
				status: this.selected,
			};
			this.tableData = this.queryTableData(searchFilter);
		},

		/**
		 * filter input result to useable dateRange
		 * params: inputDateRange (Object)
		 * retunr: useable dateRange (Array)
		 */
		filterDateRange(inputDateRange) {
			let dateRange = [];
			if (Object.values(inputDateRange).every((x) => x === null || x === '')) {
				return dateRange;
			} else {
				dateRange = Object.values(inputDateRange);
				return dateRange;
			}
		},

		queryTableData(value) {
			return this.originalTableData
				.filter((el) => {
					if (value.projectName) {
						return el.projectname
							.toLowerCase()
							.includes(value.projectName.toLowerCase());
					} else {
						return true;
					}
				})
				.filter((el) => {
					if (value.status.length) {
						console.log(value.status, el.status);
						return value.status.includes(CommonConst.STATUS_FOR_SEARCH[el.status]);
					} else {
						return true;
					}
				})
				.filter((el) => {
					if (value.clientName) {
						return el.clientname.toString() === value.clientName.toString();
					} else {
						return true;
					}
				})
				.filter((el) => {
					if (value.startDateRange.length > 0) {
						return (
							new Date(el.startdate).getTime() >= value.startDateRange[0] &&
							new Date(el.startdate).getTime() <= value.startDateRange[1]
						);
					} else {
						return true;
					}
				})
				.filter((el) => {
					if (value.deliveryDateRange.length > 0) {
						return (
							new Date(el.delivery).getTime() >= value.deliveryDateRange[0] &&
							new Date(el.delivery).getTime() <= value.deliveryDateRange[1]
						);
					} else {
						return true;
					}
				});
		},

		paginationChanged(page) {
			this.pagination.currentPage = page;
		},
	},

	mounted() {
		this.isMobile = this.checkMobile();
		socketClient.listen('new_data_transfer', this.dataTransferListener);
		socketClient.listen('new_schedule_confirm', this.dataTransferListener);
		socketClient.listen('creator_received_addcost_confirm', this.dataTransferListener);
	},
	beforeDestroy() {
		socketClient.removeListener('new_data_transfer', this.dataTransferListener);
		socketClient.removeListener('new_schedule_confirm', this.dataTransferListener);
		socketClient.removeListener(
			'creator_received_addcost_confirm',
			this.dataTransferListener
		);
	},
};
</script>
<style scoped>
.date-range_custom {
	width: 100% !important;
	font-size: 0.625rem !important;
}

.col__custom {
	display: grid;
	place-content: center;
}

.table-header {
	font-size: 0.75rem !important;
}

.scoll_hint_img {
	width: 30%;
	height: 30%;
	left: 35%;
	top: 30%;
	margin: 0 auto;
	position: absolute;
}
.not_display {
	display: none !important;
}

.select-label {
	margin-right: 10px;
}

a {
	text-decoration: none;
}

@media only screen and (max-width: 576px) {
	#checkbox-group-1 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		margin: 0;
		column-gap: 15px;
	}
}
</style>
