<template>
	<div>
		<div
			class="vfc-multiple-input"
			:class="{
				__singleDate: inputCustom,
			}"
			v-if="fConfigs.isModal && fConfigs.isDateRange"
		>
			<img
				class="vfc-multiple-input-calendar-mark"
				:src="require(`@/assets/ForComponent/CalendarIcon.svg`)"
			/>
			<input
				class="vfc-multiple-input-start-date"
				type="text"
				title="Start Date"
				v-model="dateRangeSelectedStartDate"
				placeholder="開始日"
				:readonly="!fConfigs.isTypeable"
			/>
			<img
				class="vfc-multiple-input-datepicker-mark"
				:src="require(`@/assets/ForComponent/SeparateIcon.svg`)"
			/>
			<input
				class="end-date"
				type="text"
				title="End Date"
				v-model="dateRangeSelectedEndDate"
				placeholder="終了日"
				:readonly="!fConfigs.isTypeable"
			/>
		</div>
		<div
			:class="{ 'vfc-dark': fConfigs.isDark, '--singleDate': inputCustom }"
			class="vfc-multiple-input"
			v-else-if="fConfigs.isModal && fConfigs.isDatePicker"
		>
			<img
				class="vfc-multiple-input-calendar-mark"
				:src="require(`@/assets/ForComponent/CalendarIcon.svg`)"
			/>
			<slot
				name="datePickerInput"
				:selectedDate="singleSelectedDate"
				:isTypeable="fConfigs.isTypeable"
			>
				<input
					class="vfc-single-input"
					type="text"
					title="Date"
					v-model="singleSelectedDate"
					placeholder=""
					:readonly="!fConfigs.isTypeable"
					:maxlength="fConfigs.dateFormat.length"
				/>
			</slot>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
export default {
	name: 'PickerInputs',
	props: {
		fConfigs: {
			type: Object,
			required: true,
		},
		calendar: {
			type: Object,
			required: true,
		},
		inputCustom: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		//Made by HACHIX
		dateRangeSelectedStartDate: {
			get() {
				if (this.calendar.dateRange.start !== ' ') {
					var startDate = this._formatDateString(this.calendar.dateRange.start);
					return startDate;
				} else {
					return '';
				}
			},
		},
		//Made by HACHIX
		dateRangeSelectedEndDate: {
			get() {
				if (this.calendar.dateRange.end) {
					var endDate = this._formatDateString(this.calendar.dateRange.end);
					return endDate;
				} else {
					return '';
				}
			},
		},
		singleSelectedDate: {
			get() {
				if (this.calendar.selectedDate) {
					var singleDate = this._formatDateString(this.calendar.selectedDate);
					return singleDate;
				} else {
					return '';
				}
			},
		},
	},
	methods: {
		/**
		 * Format Date string
		 * @param {String} date
		 * @returns Data in Japanese format 2021年9月18日
		 */
		//Made by HACHIX
		_formatDateString(date) {
			if (date) {
				let newDate = new Date(date);
				let year = moment(newDate).format('YYYY');
				let month = moment(newDate).format('MM');
				let day = moment(newDate).format('DD');
				return `${year}/${month}/${day}`;
			} else {
				return '';
			}
		},
	},
};
</script>

<style scoped></style>
